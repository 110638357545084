var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('my-nav-bar', {
    attrs: {
      "title": "其它收款",
      "left-text": "返回",
      "left-arrow": "",
      "right-text": "添加收款"
    },
    on: {
      "click-right": function clickRight($event) {
        _vm.showCreateDialog = true;
      }
    }
  }), _c('div', {
    staticClass: "app-main-container"
  }, [_c('van-list', {
    ref: "list",
    staticClass: "list",
    attrs: {
      "finished": _vm.finished
    },
    on: {
      "load": _vm.getList
    },
    model: {
      value: _vm.loading,
      callback: function callback($$v) {
        _vm.loading = $$v;
      },
      expression: "loading"
    }
  }, [_vm.showEmpty ? _c('van-empty', {
    attrs: {
      "description": "暂无数据"
    }
  }) : _vm._e(), _vm._l(_vm.list, function (otherPayment, index) {
    return _c('div', {
      key: index,
      staticClass: "other-payment-item"
    }, [_c('van-cell', {
      attrs: {
        "title": "状态"
      }
    }, [_c('van-tag', {
      attrs: {
        "type": otherPayment.status === 1 ? 'success' : 'warning'
      }
    }, [_vm._v(_vm._s(otherPayment.status === 1 ? '已完成' : '未完成'))])], 1), _c('van-cell', {
      attrs: {
        "title": "收款人",
        "value": otherPayment.creator.name
      }
    }), otherPayment.status === 1 ? _c('van-cell', {
      attrs: {
        "title": "收款时间",
        "value": otherPayment.received_at
      }
    }) : _vm._e(), _c('van-cell', {
      attrs: {
        "title": "收款金额",
        "value": "\uFFE5".concat(otherPayment.amount),
        "value-class": "amount-color"
      }
    }), _c('van-cell', {
      attrs: {
        "title": "备注：",
        "label": otherPayment.remark
      }
    }), otherPayment.status === 0 ? _c('van-cell', {
      attrs: {
        "title": "查看收款码",
        "is-link": ""
      },
      on: {
        "click": function click($event) {
          return _vm.handleShowPaymentQrcode(otherPayment);
        }
      }
    }) : _vm._e(), otherPayment.status === 0 ? _c('van-cell', {
      attrs: {
        "title": "操作",
        "center": ""
      }
    }, [_c('van-button', {
      attrs: {
        "type": "danger",
        "size": "mini"
      },
      on: {
        "click": function click($event) {
          return _vm.handleDeleteOtherPayment(otherPayment);
        }
      }
    }, [_vm._v("取消收款")])], 1) : _vm._e()], 1);
  })], 2)], 1), _c('my-dialog', {
    attrs: {
      "title": "添加收款"
    },
    on: {
      "confirm": _vm.handleCreateOtherPayment
    },
    model: {
      value: _vm.showCreateDialog,
      callback: function callback($$v) {
        _vm.showCreateDialog = $$v;
      },
      expression: "showCreateDialog"
    }
  }, [_c('div', {
    staticStyle: {
      "padding": "10px"
    }
  }, [_c('van-field', {
    attrs: {
      "required": "",
      "label": "收款金额",
      "type": "number",
      "placeholder": "请输入收款金额",
      "clearable": ""
    },
    model: {
      value: _vm.otherPaymentData.amount,
      callback: function callback($$v) {
        _vm.$set(_vm.otherPaymentData, "amount", $$v);
      },
      expression: "otherPaymentData.amount"
    }
  }), _c('van-field', {
    attrs: {
      "required": "",
      "label": "收款备注",
      "type": "textarea",
      "placeholder": "请输入收款备注",
      "clearable": ""
    },
    model: {
      value: _vm.otherPaymentData.remark,
      callback: function callback($$v) {
        _vm.$set(_vm.otherPaymentData, "remark", $$v);
      },
      expression: "otherPaymentData.remark"
    }
  })], 1)]), _c('my-dialog', {
    attrs: {
      "title": "其它收款码",
      "show-cancel-button": false
    },
    model: {
      value: _vm.showPaymentQrcodeDialog,
      callback: function callback($$v) {
        _vm.showPaymentQrcodeDialog = $$v;
      },
      expression: "showPaymentQrcodeDialog"
    }
  }, [_c('div', {
    staticClass: "payment-qrcode"
  }, [_c('van-image', {
    attrs: {
      "src": _vm.qrcodeData
    }
  })], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }