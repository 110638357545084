import request from '@/utils/request'

export function getOtherPayments(params) {
  return request({
    method: 'get',
    url: 'other-payment',
    params
  })
}

export function createOtherPayment(data) {
  return request({
    method: 'post',
    url: 'other-payment',
    data
  })
}

export function deleteOtherPayment(other_payment_id) {
  return request({
    method: 'delete',
    url: `other-payment/${other_payment_id}`
  })
}
