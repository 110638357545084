<template>
  <div>
    <my-nav-bar
      title="其它收款"
      left-text="返回"
      left-arrow
      right-text="添加收款"
      @click-right="showCreateDialog = true"
    />
    <div class="app-main-container">
      <van-list ref="list" v-model="loading" class="list" :finished="finished" @load="getList">
        <van-empty v-if="showEmpty" description="暂无数据" />
        <div
          v-for="(otherPayment, index) in list"
          :key="index"
          class="other-payment-item"
        >
          <van-cell title="状态">
            <van-tag :type="otherPayment.status === 1 ? 'success' : 'warning'">{{ otherPayment.status === 1 ? '已完成' : '未完成' }}</van-tag>
          </van-cell>
          <van-cell title="收款人" :value="otherPayment.creator.name" />
          <van-cell v-if="otherPayment.status === 1" title="收款时间" :value="otherPayment.received_at" />
          <van-cell
            title="收款金额"
            :value="`￥${otherPayment.amount}`"
            value-class="amount-color"
          />
          <van-cell title="备注：" :label="otherPayment.remark" />
          <van-cell
            v-if="otherPayment.status === 0"
            title="查看收款码"
            is-link
            @click="handleShowPaymentQrcode(otherPayment)"
          />
          <van-cell v-if="otherPayment.status === 0" title="操作" center>
            <van-button type="danger" size="mini" @click="handleDeleteOtherPayment(otherPayment)">取消收款</van-button>
          </van-cell>
        </div>
      </van-list>
    </div>
    <my-dialog v-model="showCreateDialog" title="添加收款" @confirm="handleCreateOtherPayment">
      <div style="padding: 10px">
        <van-field v-model="otherPaymentData.amount" required label="收款金额" type="number" placeholder="请输入收款金额" clearable />
        <van-field v-model="otherPaymentData.remark" required label="收款备注" type="textarea" placeholder="请输入收款备注" clearable />
      </div>
    </my-dialog>
    <my-dialog
      v-model="showPaymentQrcodeDialog"
      title="其它收款码"
      :show-cancel-button="false"
    >
      <div class="payment-qrcode">
        <van-image :src="qrcodeData" />
      </div>
    </my-dialog>
  </div>
</template>

<script>
import myNavBar from '@/components/my-nav-bar'
import { getOtherPayments, createOtherPayment, deleteOtherPayment } from '@/api/other-payment'
import MyDialog from '@/components/my-dialog'
import qrcode from 'qrcode'
export default {
  components: { myNavBar, MyDialog },
  data() {
    return {
      loading: false,
      finished: false,
      list: [],
      listQuery: {
        page: 0
      },
      showEmpty: false,
      showCreateDialog: false,
      otherPaymentData: {},
      showPaymentQrcodeDialog: false,
      qrcodeData: false
    }
  },
  created() {
    this.initOtherPaymentData()
  },
  methods: {
    initOtherPaymentData() {
      this.otherPaymentData = {
        amount: '',
        remark: ''
      }
    },
    reload() {
      this.listQuery.page = 0
      this.finished = false
      this.showEmpty = false
      this.list = []
      this.$refs.list.check()
    },
    getList() {
      this.listQuery.page++
      getOtherPayments(this.listQuery).then(res => {
        this.loading = false
        this.finished = res.data.list.length < 10
        this.list = this.list.concat(res.data.list)
        this.showEmpty = this.list.length === 0
      }).catch(() => {
        this.loading = false
        this.finished = true
        this.showEmpty = true
      })
    },
    handleCreateOtherPayment() {
      this.beginLoad()
      createOtherPayment(this.otherPaymentData).then(res => {
        this.endLoad()
        this.initOtherPaymentData()
        this.reload()
      })
    },
    handleShowPaymentQrcode(otherPayment) {
      if (otherPayment.status === 1) {
        return
      }
      const url = process.env.VUE_APP_PAYMENT_URL + 'other-payment/?key=' + otherPayment.key
      qrcode.toDataURL(url, { width: 200 }, (err, data) => {
        if (err) {
          this.fail('生成付款二维码失败')
          console.log(err)
          return
        }
        this.showPaymentQrcodeDialog = true
        this.qrcodeData = data
      })
    },
    handleDeleteOtherPayment(otherPayment) {
      this.confirm('确定要取消收款吗？', '提示').then(() => {
        this.beginLoad()
        deleteOtherPayment(otherPayment.id).then(res => {
          this.success(res.msg)
          this.reload()
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .list {
    padding: 10px;
  }
  .other-payment-item {
    margin-bottom: 10px;
  }
  .other-payment-item:last-child {
    margin-bottom: 0;
  }
  .payment-qrcode {
    text-align: center;
  }
</style>
